import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InputField from 'src/sharedComponents/Forms/InputField';
import { classify } from 'src/utils';
import SignUpPageTemplate from './index';
import { signupDataContext } from './signUpDataProvider';
import { initialiseSignup } from './utils';

const RegisterEnterDetails = ({ deviceType }: { deviceType: 'desktop' | 'mobile' }) => {
  const { signUpInfo, setSignUpInfo, setOtp, setPin } = useContext(signupDataContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const navigate = useNavigate();

  useEffect(() => {
    // reset info if the user pressed back from OTP page:
    setOtp('');
    setPin('');
    setSignUpInfo({
      email: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
    });
  }, []);

  async function processSubmit() {
    if (signUpInfo.phoneNumber.length !== 10) {
      return setErrorMessage('Mobile phone number must be 10 digits long.');
    }

    setIsSubmitting(true);

    try {
      await initialiseSignup(signUpInfo);
      navigate('/register/otp');
    } catch (err) {
      console.log('ERROR HITTING REG INITIALISTION', err);
      setErrorMessage(String(err));
      setIsSubmitting(false);
    }
  }

  return (
    <SignUpPageTemplate
      heading='Create your account'
      subHeading='Let’s get to know you, please give us a few details to get you set up and verify your mobile:'
      onSubmit={processSubmit}
      isSubmitting={isSubmitting}
      deviceType={deviceType}
      errorMessage={errorMessage}
      showBackButton={false}
    >
      <InputField
        label='First Name'
        autoCapitalize='words'
        autoComplete='given-name'
        autoFocus
        required
        defaultValue={signUpInfo.firstName}
        value={signUpInfo.firstName}
        onChange={(e) =>
          setSignUpInfo({
            ...signUpInfo,
            firstName: e.target.value,
          })
        }
      />

      <InputField
        label='Last Name'
        autoCapitalize='words'
        autoComplete='family-name'
        required
        defaultValue={signUpInfo.lastName}
        value={signUpInfo.lastName}
        onChange={(e) => {
          setSignUpInfo({
            ...signUpInfo,
            lastName: e.target.value,
          });
        }}
      />

      <InputField
        label='Email'
        type='email'
        autoComplete='email'
        required
        defaultValue={signUpInfo.email}
        value={signUpInfo.email}
        onChange={(e) => {
          setSignUpInfo({
            ...signUpInfo,
            email: e.target.value,
          });
        }}
      />

      <InputField
        label='Mobile Number'
        type='tel'
        pre='+91'
        autoComplete='tel'
        required
        defaultValue={signUpInfo.phoneNumber}
        value={signUpInfo.phoneNumber}
        onChange={(e) => {
          setSignUpInfo({
            ...signUpInfo,
            phoneNumber: e.target.value,
          });
        }}
      />

      <p
        className={classify([
          '!text-xs',
          'font-telegrafRegular ml-6 text-xs leading-[1.1] text-black/50',
        ])}
      >
        Saathi is currently only available to residents of India
      </p>
    </SignUpPageTemplate>
  );
};
export default RegisterEnterDetails;
