import './Footer.scss';
import logo from './assets/footer-logo.png';

export default function Footer({ pageLocation, setShowContactUsModalBool }) {
  if (pageLocation === '/cfsub/success' || pageLocation === '/cfsub/failure' || pageLocation === '/cfsub/flexxpayfailure' || pageLocation === '/cfsub/flexxpaysuccess' || pageLocation === '/vkyc/success' || pageLocation === '/vkyc/failure') {
    return null;
  }

  return (
    <footer>
      <div class='container'>
        <div class='contact'>
          <img src={logo} alt='Saathi Logo' />

          <div class='contact-details'>
            <p>Contact the Saathi team (Mon-Fri, 9am to 6pm)</p>
            <ul>
              <li>
                <a href='tel:0091 80 694 90468'>+91 80 694 90468</a>
              </li>
              <li>
                <a href='mailto:support@besaathi.com'>support@besaathi.com</a>
              </li>
              <li>
                <a href='https://goo.gl/maps/yMuQzTvyWLee1txp7' target='_blank'>
                  502 Sagar Fortune, 184 Waterfield Road, Bandra West, Mumbai,
                  400 050
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class='disclaimer'>
          <p>
            Saathi is a money management platform that re-imagines the banking
            experience in India. The digital bank account, offered through our
            App, gives you one of the fastest way to open a bank account online
            and is provided by the bank of smiles, Suryoday.
          </p>

          <p>
            You can do everything from the Saathi App, including send and
            receive money, setup regular savings payments, setup savings goals
            for yourself or with friends and family, get amazing discounts and
            cashback and track your financial progress. You get a Virtual Debit
            Card for spending your saved money.
          </p>
        </div>
      </div>
      <div class='container'>
        <div class='copyright'>
          <p>© 2023 Saathi Group Ltd</p>
        </div>
        <div class='links'>
          <ul>
            <li>
              <a href='/LegalDocs/TermsOfUse'>Terms of Use</a>
            </li>
            <li>
              <a href='/LegalDocs/PrivacyPolicy'>Privacy Policy</a>
            </li>
            <li>
              <a href='/LegalDocs/Fees'>Fees</a>
            </li>
            <li>
              <a href='/LegalDocs/Grievances'>Grievances</a>
            </li>
            <li>
              <a href='/Resources/FAQ'>FAQs</a>
            </li>
          </ul>
          <ul>
            <li>
              <span
                data-text='7af849b24bc54d1c944f333736ca3e2ec45d88697c877c128e9f4371cce59048'
                class='tooltip'
              >
                iOS Checksum
              </span>
            </li>
            <li>
              <span
                data-text='7af849b24bc54d1c944f333736ca3e2ec45d88697c877c128e9f4371cce59048'
                class='tooltip'
              >
                Android Checksum
              </span>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}
