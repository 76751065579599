const HeroSection = ({ deviceType, scrollDownToMerchantLogos }) => {
  return (
    <div className='[background:linear-gradient(180deg,#FFF_7.41%,rgba(255,255,255,0.50)100%),radial-gradient(98.33%_50%_at_50%_50%,#FBA4DA_0%,#99C7FB_100%)]'>
      <div className='relative mx-auto w-full px-6 pb-12 pt-24 2xl:container md:px-10 md:py-24 lg:py-36'>
        <div className='grid grid-cols-12 items-center gap-4 lg:gap-6'>
          <div className='col-span-12 md:col-span-5'>
            <div className='flex max-w-[487px] flex-col gap-y-6 lg:gap-y-10 xl:gap-y-14'>
              <h1 className='-my-2 text-4xl font-bold md:text-5xl lg:text-6xl'>
                Get rewarded when you save for your goals
              </h1>

              <div className='flex flex-col gap-y-6 lg:gap-y-8'>
                <p className='-my-1 lg:text-2xl'>
                  High-interest digital savings account + awesome rewards so you can save for the
                  things you love.
                </p>

                <a
                  href='#register'
                  className='group relative flex h-12 w-fit items-center overflow-hidden rounded-xl bg-gradient-to-r from-purple to-blue px-6 text-lg font-bold text-white md:text-xl lg:text-2xl'
                >
                  <span className='absolute inset-0 bg-gradient-to-r from-blue to-purple opacity-0 transition-opacity group-hover:opacity-100' />
                  <span className='isolate'>Join the savings programme</span>
                </a>
              </div>
            </div>
          </div>

          <div className='relative z-10 col-span-12 md:col-span-2'>
            <img src='/device.png' className='w-full max-w-none md:w-[163.94849785%]' />
          </div>

          <div className='col-span-12 md:col-span-5 lg:col-span-4'>
            <div className='flex flex-col items-start justify-end gap-y-3'>
              <div className='relative w-full max-w-[534px] rounded-3xl bg-pink/[16%] p-6 text-pink md:pl-[19%]'>
                <p className='font-telegrafUltraBold !text-xl md:!text-2xl xl:!text-3xl'>
                  Save
                  <br />
                  For A Goal
                </p>

                <img
                  src='/people.png'
                  className='absolute right-0 top-1/2 w-[40%] -translate-y-1/2 translate-x-[6.5%] md:top-0 md:w-[154px] md:-translate-y-1/4 lg:w-[192px] lg:-translate-y-1/2 xl:w-[240px]'
                />
              </div>

              <div className='relative w-full max-w-[534px] rounded-3xl bg-purple/[16%] p-6 text-purple md:pl-[19%]'>
                <p className='font-telegrafUltraBold !text-xl md:!text-2xl xl:!text-3xl'>
                  Earn
                  <br />
                  Guaranteed
                  <br />
                  Interest
                </p>

                <img
                  src='/up-to.png'
                  className='absolute right-0 top-1/2 w-[45%] -translate-y-1/2 translate-x-[10%] md:top-0 md:w-[147px] md:-translate-y-[28%] lg:w-[183px] xl:w-[229px]'
                />
              </div>

              <a href='#merchants' className='relative w-full max-w-[534px] rounded-3xl bg-blue/[16%] p-6 text-blue md:pl-[19%]'>
                <p className='font-telegrafUltraBold text-xl md:text-2xl xl:text-3xl '>
                  Earn Extra 
                  <br/> Rewards
                </p>
                
                <img
                  src='/rewards.png'
                  className='absolute right-2 top-1/2 w-[40%] -translate-y-1/2 md:w-[94px] lg:w-[118px] xl:w-[148px]'
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HeroSection;
