import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import OtpInput from 'react18-input-otp';
import SignUpPageTemplate from './index';
import { signupDataContext } from './signUpDataProvider';
import { initialiseSignup, finaliseSignup } from './utils';

const RegisterEnterOTP = ({ deviceType }: { deviceType: 'desktop' | 'mobile' }) => {
  const { signUpInfo, otp, setOtp } = useContext(signupDataContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const navigate = useNavigate();

  useEffect(() => {
    if (!signUpInfo.phoneNumber) {
      navigate('/register/EnterDetails');
    }
  }, [signUpInfo]);

  async function processSubmit() {
    if (otp.length !== 6) {
      return setErrorMessage('Please enter the 6-digit OTP sent to your phone.');
    }

    setIsSubmitting(true);

    try {
      await finaliseSignup(signUpInfo, otp, '');
      navigate('/register/EnterPin');
    } catch (err) {
      console.log('ERROR FINALISING OTP', err);
      setErrorMessage('Invalid OTP, please try again.');
      setIsSubmitting(false);
    }
  }

  return (
    <SignUpPageTemplate
      heading='Verify your mobile'
      subHeading='Please enter the 6-digit OTP sent via SMS to the mobile number you just entered:'
      onSubmit={processSubmit}
      isSubmitting={isSubmitting}
      deviceType={deviceType}
      errorMessage={errorMessage}
    >
      <OtpInput
        value={otp}
        onChange={(value) => setOtp(value)}
        numInputs={6}
        containerStyle={{
          display: 'flex',
          gap: '4px',
        }}
        inputStyle={{
          flex: 1,
          height: '40px',
          width: '40px',
          textAlign: 'center',
          fontSize: '20px',
          fontWeight: 'bold',
        }}
      />
    </SignUpPageTemplate>
  );
};
export default RegisterEnterOTP;
