import { createContext, useState } from 'react';
import { Outlet } from 'react-router-dom';

export declare type SignupInfo = {
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  currentSavings?: string;
  savingFor?: string;
  gainFromProgramme?: string;
};

export const signupDataContext = createContext<{
  signUpInfo: SignupInfo;
  setSignUpInfo: React.Dispatch<SignupInfo>;
  otp: string;
  setOtp: React.Dispatch<string>;
  pin: string;
  setPin: React.Dispatch<string>;
}>(null);

export const SignupDataProvider = ({}) => {
  const [signUpInfo, setSignUpInfo] = useState<SignupInfo>({});

  const [otp, setOtp] = useState('');
  const [pin, setPin] = useState('');

  return (
    <signupDataContext.Provider
      value={{
        signUpInfo,
        setSignUpInfo,
        otp,
        setOtp,
        pin,
        setPin,
      }}
    >
      <Outlet />
    </signupDataContext.Provider>
  );
};
