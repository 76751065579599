import React from 'react';
import { useState, useEffect } from 'react';
import './style.scss';
import community from '../../../assets/images/community.svg';
import rewards from '../../../assets/images/rewardsnew.svg';
import careerskills from '../../../assets/images/career skills.svg';

import communitySavers from '../../../assets/images/diversity-unity-2.svg';
import discordChat from '../../../assets/images/chat-bot.svg';
import accelerateDreams from '../../../assets/images/saving-money-3.svg';
import expertInsights from '../../../assets/images/motivate-someone-1.svg';

function Reasons({ items }) {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setActiveIndex((activeIndex + 1) % items.length);
    }, 6000);

    return () => clearInterval(intervalId);
  }, [activeIndex, items.length]);

  return (
    <ul className='quotes'>
      {items.map((item, index) => (
        <li
          key={index}
          className={`wrap container item ${
            index === activeIndex ? 'active' : ''
          }`}
        >
          <div className='graphic'>
            <img className='imageOnGraphic' src={item.image} />
          </div>
          <ul className='indicators'>
            <li style={activeIndex == 0 ? { background: '#000000' } : {}}></li>
            <li style={activeIndex == 1 ? { background: '#000000' } : {}}></li>
            <li style={activeIndex == 2 ? { background: '#000000' } : {}}></li>
          </ul>
          <div className='content'>
            <span className='subheading'>Why Join the Savings Club?</span>
            <h3>{item.heading}</h3>
            <p>{item.text}</p>
          </div>
        </li>
      ))}
    </ul>
  );
}

export default function Why({}) {
  const items = [
    {
      id: 1,
      heading: 'Be part of a Savings Community',
      text: 'Connect with a like-minded community of individuals passionate about financial freedom, fostering accountability and motivation to stay on track with your savings goals. Share insights, tips, and success stories on your saving journey.',
      image: communitySavers,
    },
    {
      id: 2,
      heading: 'Accelerate Your Dreams',
      text: "We believe in your dreams. As a member, you will receive support, encouragement and rewards to accelerate your dreams, whether it's a travel goal or learning a new instrument. The Saathi Savings Club stands beside you, turning your dreams into tangible achievements.",
      image: accelerateDreams,
    },
    {
      id: 3,
      heading: 'Expert Insights',
      text: 'Access valuable insights, educational resources, and expert advice on smart saving strategies to maximize your financial potential and start Flexxing!',
      image: expertInsights,
    },
    /*
    {
      id: 1,
      heading: "Build career skills and gain exposure",
      text: "Join the Savings Club at Saathi and you'll get to be on the front-seat of a growing fintech platform, by being an active contributor as an early adopter.",
      image: careerskills,
    },
    {
      id: 2,
      heading: "Get up to double the rewards for saving and championing goals",
      text: "When you join the Founder's Club, you earn extra rewards towards your savings goals, as well as encourage other people to save towards their goals.",
      image: rewards,
    },
    {
      id: 3,
      heading: "Make a positive difference in your community",
      text: "Saathi will be supporting a series of community goals that are geared towards improving the wellbeing of our society from women empowerment to enhancing biodiversity. Leverage the Saathi platform to champion a cause that you care about and make a positive difference in your community.",
      image: community,
    },
*/
  ];

  return (
    <section className='why'>
      <Reasons items={items} />
    </section>
  );
}
