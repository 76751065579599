import successImg from "../../assets/images/success.png";
import React from "react";

const sectionStyle = {
  width: "400px",
  fontSize: 16,
  color: "#4a54f1",
  textAlign: "center",
  padding: "30px",  background: "#FFFFFF",
};

const smallText = {
  fontSize: 12,
  color: "#4a54f1",
  textAlign: "center",
};

const buttonStyle = {
  borderRadius: "4px",
  padding: "4px",
  color: "#ffffff",
  background: "#000000",
};

const imgStyle = { width: "200px", height: "200px" };

const onClose = () => {
  window.opener = null;
  window.open("", "_self");
  window.close();
};

export default function VKYCSuccess({}) {
  return (
    <section style={sectionStyle}>
      <p>Yes! Your vKYC was successful!</p>
      <img src={successImg} style={imgStyle} alt="vKYC success" />
      <div>
        {/* <button style={buttonStyle} onClick={onClose}>
          Close
        </button> */}
      </div>
      <p style={smallText}>Any concerns, please contact support.</p>
    </section>
  );
}
