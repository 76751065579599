import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InputField from 'src/sharedComponents/Forms/InputField';
import { classify } from 'src/utils';
import SignUpPageTemplate from './SignUpPageTemplate';
import { signupDataContext } from './SignupDataProvider';
import { initialiseSignup } from './utils';

const SavingsProgrammeEnterDetails = ({ deviceType }: { deviceType: 'desktop' | 'mobile' }) => {
  const { signUpInfo, setSignUpInfo, setOtp, setPin } = useContext(signupDataContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const navigate = useNavigate();

  useEffect(() => {
    // reset info if the user pressed back from OTP page:
    setOtp('');
    setPin('');
    setSignUpInfo({
      email: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      currentSavings: '',
      savingFor: '',
      gainFromProgramme: '',
    });
  }, []);

  // Validate only alphabetic characters
  const handleAlphaInputChange =
    (field: 'firstName' | 'lastName') => (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      // Allow only alphabetic characters
      const regex = /^[a-zA-Z]*$/;
      if (regex.test(value)) {
        setSignUpInfo({
          ...signUpInfo,
          [field]: value,
        });
      }
    };

  async function processSubmit() {
    if (signUpInfo.phoneNumber.length !== 10)
      return setErrorMessage('Mobile phone number must be 10 digits long.');

    setIsSubmitting(true);

    try {
      await initialiseSignup(signUpInfo).then(() => {
        navigate('/suryoday/SavingsProgramme/EnterOTP');
      });
    } catch (err) {
      console.log('ERROR HITTING REG INITIALISTION', err);
      setErrorMessage(err);
      setIsSubmitting(false);
    }
  }

  return (
    <SignUpPageTemplate
      heading='Create your account'
      subHeading='Let’s get to know you, please give us a few details to get you set up and verify your mobile:'
      onSubmit={processSubmit}
      isSubmitting={isSubmitting}
      deviceType={deviceType}
      errorMessage={errorMessage}
      showBackButton={false}
    >
      <InputField
        label='First Name*'
        autoCapitalize='words'
        autoComplete='given-name'
        pattern='[a-zA-Z]+'
        autoFocus
        required
        defaultValue={signUpInfo.firstName}
        value={signUpInfo.firstName}
        onChange={handleAlphaInputChange('firstName')}
      />

      <InputField
        label='Last Name*'
        autoCapitalize='words'
        autoComplete='family-name'
        pattern='[a-zA-Z]+'
        required
        defaultValue={signUpInfo.lastName}
        value={signUpInfo.lastName}
        onChange={handleAlphaInputChange('lastName')}
      />

      <InputField
        label='Email*'
        type='email'
        autoComplete='email'
        required
        defaultValue={signUpInfo.email}
        value={signUpInfo.email}
        onChange={(e) => {
          setSignUpInfo({
            ...signUpInfo,
            email: e.target.value,
          });
        }}
      />

      <InputField
        label='Mobile Number*'
        type='tel'
        pre='+91'
        autoComplete='tel'
        required
        defaultValue={signUpInfo.phoneNumber}
        value={signUpInfo.phoneNumber}
        onChange={(e) => {
          setSignUpInfo({
            ...signUpInfo,
            phoneNumber: e.target.value,
          });
        }}
      />

      <p
        className={classify([
          '!text-xs',
          'ml-6 font-telegrafRegular text-xs leading-[1.1] text-black/50',
        ])}
      >
        Saathi is currently only available to residents of India
      </p>

      <label className='relative flex cursor-text flex-row-reverse gap-x-3 rounded-2xl bg-white px-6 shadow-md'>
        <select
          name='savings'
          id='savings'
          className={classify([
            'peer block h-16 w-full appearance-none pt-4 font-telegrafSemiBold focus:!outline-none lg:text-xl',
          ])}
          onChange={(e) => {
            setSignUpInfo({
              ...signUpInfo,
              currentSavings: e.target.value,
            });
          }}
        >
          <option disabled selected className='opacity-40'>
            Please select an option...
          </option>

          <option value='yes'>Yes</option>
          <option value='no'>No</option>
        </select>

        <span
          className={classify([
            'pointer-events-none absolute inset-0 flex origin-top-left -translate-y-3 items-center px-6 text-xs opacity-40',
            'font-saathiUltraBold',
          ])}
        >
          Do you currently have any savings?
        </span>
      </label>

      <label className='relative flex cursor-text flex-row-reverse gap-x-3 overflow-hidden rounded-2xl bg-white px-6 shadow-md'>
        <select
          name='what'
          id='what'
          className={classify([
            'peer block h-16 w-full appearance-none pt-4 font-telegrafSemiBold focus:!outline-none lg:text-xl',
          ])}
          onChange={(e) => {
            setSignUpInfo({
              ...signUpInfo,
              savingFor: e.target.value,
            });
          }}
        >
          <option disabled selected>
            Please select an option...
          </option>

          <option value='short-term'>
            For a short term goal - such as buying a gadget, or saving for a holiday
          </option>

          <option value='medium-long'>
            For a medium/long term goal (e.g. house, marriage, education, retirement)
          </option>

          <option value='emergencies'>For emergencies - health, general</option>
        </select>

        <span
          className={classify([
            'pointer-events-none absolute inset-0 flex origin-top-left -translate-y-3 items-center px-6 text-xs opacity-40',
            'font-saathiUltraBold',
          ])}
        >
          What are you saving for / would you like to save for?
        </span>
      </label>

      <label className='relative flex cursor-text flex-row-reverse gap-x-3 rounded-2xl bg-white px-6 shadow-md'>
        <select
          name='savings'
          id='savings'
          className={classify([
            'peer block h-16 w-full appearance-none pt-4 font-telegrafSemiBold focus:!outline-none lg:text-xl',
          ])}
          onChange={(e) => {
            setSignUpInfo({
              ...signUpInfo,
              gainFromProgramme: e.target.value,
            });
          }}
        >
          <option disabled selected>
            Please select an option...
          </option>

          <option value='yes'>Build a savings habit</option>
          <option value='yes'>Get rewards/discounts on future purchases</option>
          <option value='yes'>Unsure, just keen to explore</option>
        </select>

        <span
          className={classify([
            'pointer-events-none absolute inset-0 flex origin-top-left -translate-y-3 items-center px-6 text-xs opacity-40',
            'font-saathiUltraBold',
          ])}
        >
          What would you like to gain from this programme?
        </span>
      </label>

      <p className={classify(['!text-xs', 'ml-6 mt-2 font-telegrafRegular text-xs leading-[1.1]'])}>
        By clicking "Continue", you are acknowledging and agreeing to this disclaimer and the
        subsequent use of Saathi.
      </p>

      <p className={classify(['!text-xs', 'ml-6 font-telegrafRegular text-xs leading-[1.1]'])}>
        You acknowledge that for the provision of these products and services, Suryoday Bank may
        need to share your data available with Suryoday Bank with Saathi. By opting in to this
        programme, you authorize Suryoday Bank to share your data and customer information with
        Saathi to enable the provision of the aforementioned products and services.
      </p>

      <p className={classify(['!text-xs', 'ml-6 font-telegrafRegular text-xs leading-[1.1]'])}>
        You can find the detailed T&C over here:{' '}
        <a
          target='_blank'
          href='https://www.saathi.money/LegalDocs/TermsOfUse'
          className='underline'
        >
          https://www.saathi.money/LegalDocs/TermsOfUse
        </a>
      </p>
    </SignUpPageTemplate>
  );
};
export default SavingsProgrammeEnterDetails;
