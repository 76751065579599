import './AppStoreButtons.scss';
import appleAppStoreSVG from './AppleAppStore.svg';
import googlePlaySVG from './GooglePlay.svg';

var googleLink =
  process.env.REACT_APP_PROJECT_LIVE_BOOLEAN === 'true'
    ? process.env.REACT_APP_PLAY_STORE
    : process.env.REACT_APP_EARLY_PLAY_STORE;

var appleLink =
  process.env.REACT_APP_PROJECT_LIVE_BOOLEAN === 'true'
    ? process.env.REACT_APP_APP_STORE
    : process.env.REACT_APP_EARLY_APP_STORE;

export const AppleAppStoreButton = () => (
  <a href={appleLink} target='_blank' rel='noreferrer' className='appStoreButton'>
    <img src={appleAppStoreSVG} alt='Apple app store icon' />
  </a>
);

export const GooglePlayStoreButton = () => (
  <a href={googleLink} target='_blank' rel='noreferrer' className='appStoreButton'>
    <img src={googlePlaySVG} alt='Google play store icon' />
  </a>
);
